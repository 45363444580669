import moment from 'moment';
import { ADDRESS, DATE_FORMAT_MOMENT, EMAIL, EXTRAS, LAST_NAME, MAINTENANCE_EMAIL, MAINTENANCE_PHONE, NAME, PHONE, RESERVATION_COST, RESERVATION_DATE_FOR, RESERVATION_MADE, RESERVATION_SCHEDULE, } from 'src/Utils';
import { handleAmenityCurrency } from 'src/Views/MaintenancePages/ReservationPage/utils';
export var getBuildingData = function (reservation) { return ({
    building: reservation.building_name || '-',
    apartment: reservation.apartment_number || '-',
    amenity: reservation.amenity_name || '-',
    description: reservation.amenity_description || '-',
}); };
export var getValuesBookedBy = function (reservation, isAdmin) {
    var values = [
        { label: NAME, value: reservation.created_by.first_name || '-' },
        { label: LAST_NAME, value: reservation.created_by.last_name || '-' },
    ];
    if (!isAdmin) {
        values.push({ label: PHONE, value: reservation.created_by.phone || '-' }, { label: EMAIL, value: reservation.created_by.email || '-' }, { label: ADDRESS, value: reservation.created_by.address || '-' });
    }
    return values;
};
export var getValuesAmenity = function (reservation) { return [
    { label: MAINTENANCE_PHONE, value: reservation.amenity_phone || '-' },
    { label: MAINTENANCE_EMAIL, value: reservation.amenity_email || '-' },
]; };
export var getShedulesValues = function (reservation) { return [
    {
        title: RESERVATION_DATE_FOR,
        values: [moment(reservation.day).format(DATE_FORMAT_MOMENT)] || '-',
    },
    { title: RESERVATION_SCHEDULE, values: [reservation.schedule] || '-' },
]; };
export var getDateValues = function (reservation) { return [
    {
        title: RESERVATION_MADE,
        values: [
            moment(reservation.created_at.split('T')[0]).format(DATE_FORMAT_MOMENT),
        ],
    },
]; };
export var getExtrasValues = function (reservation) { return [
    {
        title: EXTRAS,
        values: reservation.extras.length ? reservation.extras : ['-'],
    },
]; };
export var getCostValues = function (reservation) {
    var currencyToShow = handleAmenityCurrency(reservation.currency);
    return [
        {
            title: RESERVATION_COST,
            values: [
                currencyToShow === 'UR'
                    ? reservation.price + " " + currencyToShow
                    : currencyToShow + " " + reservation.price,
            ],
        },
    ];
};
